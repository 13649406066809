/* 
primary     #f71735
primFaded   #ff230a
secondary   #474747
grey        #eef0f2
black       #353b3c
white       white
*/

body {
  background-color: black;
  font-family: "Montserrat", sans-serif;
  overflow-x: scroll;
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 400px;
}

#app-main-container {
  display: inline-flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow-x: scroll;
}

.p5Canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1
}

/* Top Header */
#top-container {
  position: relative;
  width: 100%;
  height: 500px;
}

#outer-header-wrapper {
  width: calc(100% - 400px);
  z-index: 99999;
  height: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 200px;
  margin-right: 200px;
}

#inner-header-wrapper {
  position: absolute;
  width: 380px;
}

#main-title {
  margin: 0;
  font-size: 96px;
  font-family: "Montserrat", sans-serif;
  color: #f71735;
}

#main-description {
  margin: 0;
  margin-top: -8px;
  line-height: 32px;
  font-size: 38px;
  font-family: "Montserrat", sans-serif;
  color: white;
}

#sign-up-now-main-bttn {
  background-color: #f71735;
  border-radius: 12px;
  border: none;
  padding: 8px 24px;
  color: white;
  font-family: "Montserrat", sans-serif;
  margin-top: 16px;
  font-weight: bold;
}

button:hover {
  cursor: pointer;
  filter: brightness(80%);
}

/* Main Body */
#bottom-container {
  margin: auto;
  color: white;
  padding-top: 24px;
  width: calc(100% - 400px);
}

.bottom-container-subheader {
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
  color: white;
  margin: 0;
  width: 800px;
}

#bottom-container>article {
  display: inline-flex;
  justify-content: center;
  align-items: top;
  width: calc(100% - 48px);
  padding: 24px;
  margin-bottom: 80px;
}

#bottom-container>article:nth-child(2n) {
  background-color: rgb(41, 28, 28);
  border-radius: 12px;
  flex-direction: row-reverse;
}

#bottom-container>article:nth-child(2n)>.bottom-container-subheader {
  padding-left: 48px;
  text-align: right;
}

#bottom-container>article p {
  font-size: 14px;
  margin-top: 0px;
  line-height: 24px;
}

#signup-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 48px;
}

#signup-container #signup-container-subheader {
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
  color: white;
  margin: 0;
}

#sign-up-now-bottom-container-bttn {
  width: 240px;
  margin: auto;
  margin-top: 12px;
}

#sign-up-now-bottom-container-bttn button {
  background-color: #f71735;
  border-radius: 12px;
  border: none;
  padding: 8px 24px;
  color: white;
  width: 240px;
  font-weight: bold;
  margin: auto;
  font-family: "Montserrat", sans-serif;
}

.emphasis-txt {
  color: #f71735;
}

/* Footer */
#footer-container {
  border-top: 4px solid white;
  text-align: center;
  padding: 12px;
}

#footer-container>h5 {
  font-size: 11px;
  margin-bottom: 0;
  color: white;
}

#footer-container>h6 {
  margin-top: 4px;
  font-size: 8px;
  color: rgb(202, 202, 202);
}

@media only screen and (max-width: 1000px) {
  #inner-header-wrapper {
    position: absolute;
    top: 120px;
    left: calc(50% - 170px);
    width: 340px;
  }

  #bottom-container {
    margin: auto;
    color: white;
    padding-top: 24px;
    width: 100%;
  }

  #bottom-container>article {
    flex-direction: column;
  }

  .bottom-container-subheader {
    width: auto;
    padding-right: 0;
  }

  #bottom-container>article:nth-child(2n) {
    flex-direction: column;
  }

  #bottom-container>article:nth-child(2n)>.bottom-container-subheader {
    text-align: left;
  }
}

/* Design for Contact Form */
#form-page-outer-container {
  display: inline-flex;
  padding: 0;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 48px;
  margin-top: -48px;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 24px);
  height: 100%;
  overflow-x: scroll;
}

#form-page-inner-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 600px;
  min-width: 300px;
  margin-top: 120px;
  background-color: rgb(66, 57, 57);
  border-radius: 16px;
  padding: 24px;
  border: 2px solid rgb(177, 177, 177);
}

#form-page-inner-container #contact-us-header {
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
  color: white;
  margin: 0;
}

#go-back-bttn {
  margin-bottom: 12px;

  align-self: flex-start;
}

#go-back-bttn>button {
  background: none;
  border: none;
  background: none;
  color: rgb(168, 168, 168);
}

#form-page-inner-container .input-wrapper {
  display: flex;
  padding-bottom: 12px;
  padding-top: 12px;
  flex-direction: column;
}

#form-page-inner-container .input-wrapper label {
  width: 100%;
  padding-bottom: 4px;
  color: white;
  font-size: 11px;
}

#form-page-inner-container .input-wrapper input, textarea {
  border: none;
  background-color: rgb(235, 235, 235);
  border-radius: 4px;
  resize: none;
  padding: 8px 12px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: black;
}

#form-page-inner-container textarea {
  min-height: 120px;
}

.required-input {
  color: red;
}

.error-message {
  color: red;
  text-align: center;
  font-size: 12px;
  margin-top: 16px;
}

#form-successfully-submitted {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 600px;
  min-width: 300px;
  margin-top: 240px;
  background-color: rgb(66, 57, 57);
  border-radius: 16px;
  padding: 24px;
  border: 2px solid rgb(177, 177, 177);
  
}

#form-success-header {
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
  color: white;
  padding: 0;
  margin: 0;
  float: none;
  text-align: center;
}

#form-page-inner-container p {
  color: white;
  font-size: 14px;
}

#form-successfully-submitted p {
  color: white;
  font-size: 14px;
  text-align: center;
}

#form-main-container-bttn {
  margin-top: 16px;
  width: 100%;
}

#form-main-container-bttn>button {
  background-color: #175ef7;
  border-radius: 12px;
  border: none;
  padding: 12px 24px;
  color: white;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  font-weight: bold;
}